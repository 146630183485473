<template>
    <el-container class="page-container">
        <!--<el-header class="page-header" style="min-height: 60px;height: auto">-->

        <!--</el-header>-->
        <el-main class="page-main" style="padding: 0 20px">
            <br>
            <el-form label-position='left' label-width="80px" :model="query" ref="searchForm">
                <el-row :gutter="20">
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-input placeholder="请输入内容" v-model="query.searchKeyword" @keyup.enter.native="doSearch">
                            <el-select v-model="query.searchName" slot="prepend" placeholder="查询类型"
                                       style="width: 120px;">
                                <el-option label="位置ID" value="positionId"></el-option>
                                <el-option label="标题" value="title"></el-option>
                                <el-option label="副标题" value="subTitle"></el-option>
                                <el-option label="描述" value="descriptions"></el-option>
                                <el-option label="跳转方式" value="switchType"></el-option>
                                <el-option label="跳转地址" value="url"></el-option>
                                <el-option label="图片" value="picUrl"></el-option>
                                <el-option label="html内容" value="describeContent"></el-option>
                                <el-option label="排序" value="orderIndex"></el-option>
                                <el-option label="是否展示" value="isShow"></el-option>
                                <el-option label="过期时间（可为空）" value="deadTime"></el-option>
                                <el-option label="创建时间" value="createTime"></el-option>
                                <el-option label="是否系统广告" value="sysAd"></el-option>
                                <el-option label="餐点ID" value="siteId"></el-option>
                            </el-select>
                        </el-input>
                    </el-col>
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-form-item label-width="0">
                            <el-button type="primary" @click="handleSearch">
                                <i class="cw-icon cw-icon-sousuo"></i>搜索
                            </el-button>
                            <el-button type="primary" plain @click="clearSearch">
                                <i class="cw-icon cw-icon-zhongzhi"></i>重置
                            </el-button>
                        </el-form-item>
                    </el-col>
                </el-row>

            </el-form>

            <el-row class="row-btn-group">
                <el-button type="primary" @click="handleAdd('新增')">
                    <i class="cw-icon cw-icon-tianjia"></i>新增
                </el-button>
                <el-button type="danger" @click="doDelMore" :disabled="selectData.length==0">
                    <i class="cw-icon cw-icon-tianjia"></i>删除选中
                </el-button>
            </el-row>
            <el-table
                    :data="tableData"
                    ref="multipleTable"
                    @selection-change="handleSelectionChange"
                    style="width: 100%">
                <el-table-column
                        type="selection"
                        width="55">
                </el-table-column>
                <el-table-column
                        prop="positionId"
                        label="位置ID"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                        prop="title"
                        label="标题"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>

                <el-table-column
                        prop="descriptions"
                        label="描述"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                        prop="switchType"
                        label="跳转方式"
                        :show-overflow-tooltip="true"
                        :formatter="formatSwitchType"
                >

                </el-table-column>
                <el-table-column
                        prop="url"
                        label="跳转地址"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                        prop="picUrl"
                        label="图片"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>


                <el-table-column
                        prop="orderIndex"
                        label="排序"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>

                <el-table-column
                        prop="isShow"
                        label="是否展示"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                        prop="deadTime"
                        label="过期时间"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                        prop="createTime"
                        label="创建时间"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                        prop="sysAd"
                        label="是否系统广告"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                        prop="siteId"
                        label="餐点id"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                        fixed="right"
                        width="180"
                        label="操作">
                    <template slot-scope="scope">
                        <el-button size="mini" type="success" @click="handleEdit(scope.row)" plain>修改</el-button>
                        <el-button size="mini" type="success" @click="doDelete(scope.row)" plain>删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <br>
            <el-pagination
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageInfo.pageNumber"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="pageInfo.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageInfo.totalCount">
            </el-pagination>
        </el-main>
        <!--//编辑管理员-->
        <form-dialog @close="handleClose('editForm')" :title="editTitle" :dialogVisible="visibleFormDialog"
                     width="800px">
            <template slot="content">
                <el-form :model="formData" ref="editForm" :rules="formRules" label-position="right" label-width="85px"
                         class="adsdia">
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <el-form-item prop="positionId" label="广告位置">
                                <el-select v-model="formData.positionId" placeholder="广告位置" style="width: 100%;">
                                    <el-option v-for="(item,index) in posData" :key="index" :label="item.name"
                                               :value="item.id"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item prop="title" label="标题">
                                <el-input maxlength="100" placeholder="标题"
                                          v-model="formData.title"
                                          auto-complete="off" tabindex="3" type="text"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item prop="subTitle" label="副标题">
                                <el-input maxlength="100" placeholder="副标题"
                                          v-model="formData.subTitle"
                                          auto-complete="off" tabindex="4" type="text"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item prop="descriptions" label="描述">
                                <el-input maxlength="100" placeholder="描述"
                                          v-model="formData.descriptions"
                                          auto-complete="off" tabindex="5" type="text"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item prop="switchType" label="跳转方式">
                                <el-select v-model="formData.switchType" placeholder="请选择">
                                    <el-option
                                            v-for="item in switchTypes"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item prop="url" label="跳转地址">

                                <el-input maxlength="100" placeholder="跳转地址"
                                          v-model="formData.url"
                                          auto-complete="off" tabindex="5" type="text"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item prop="picUrl" label="图片">
                                <Upload @success="handleSuccess" :imgUrl="formData.picUrl"/>
                                <!--                            <el-input maxlength="100" placeholder="图片"-->
                                <!--                                      v-model="formData.picUrl"-->
                                <!--                                      auto-complete="off" tabindex="5" type="text"></el-input>-->
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item prop="describeContent" label="html内容">
                                <el-input maxlength="100" placeholder="html内容"
                                          v-model="formData.describeContent"
                                          auto-complete="off" tabindex="5" type="text"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item prop="orderIndex" label="排序">
                                <el-input maxlength="100" placeholder="排序"
                                          v-model="formData.orderIndex"
                                          auto-complete="off" tabindex="5" type="text"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item prop="isShow" label="是否展示">
                                <el-input maxlength="100" placeholder="是否展示"
                                          v-model="formData.isShow"
                                          auto-complete="off" tabindex="5" type="text"></el-input>
                                <!--                            <el-radio-group v-model="formData.isShow">-->
                                <!--                                <el-radio :label="1" size="mini" border style="margin-right: 0">是</el-radio>-->
                                <!--                                <el-radio :label="0" size="mini" border style="margin-right: 0">否</el-radio>-->
                                <!--                            </el-radio-group>-->
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item prop="deadTime" label="过期时间">
                                <el-date-picker
                                        v-model="formData.deadTime"
                                        type="datetime"
                                        placeholder="过期时间"
                                        style="width: 100%"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item prop="sysAd" label="系统广告">
                                <el-input maxlength="100" placeholder="是否系统广告"
                                          v-model="formData.sysAd"
                                          auto-complete="off" tabindex="5" type="text"></el-input>
                                <!--                            <el-radio-group v-model="formData.isShow">-->
                                <!--                                <el-radio :label="1" size="mini" border style="margin-right: 0">是</el-radio>-->
                                <!--                                <el-radio :label="0" size="mini" border style="margin-right: 0">否</el-radio>-->
                                <!--                            </el-radio-group>-->
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item prop="siteId" label="餐点名称">
                                <el-select v-model="formData.siteId" placeholder="餐点名称" style="width: 100%;">
                                    <el-option v-for="(item,index) in siteDataArr" :key="index" :label="item.name"
                                               :value="item.id"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
                <div class="flex flex-direction-row-reverse btn-group">
                    <el-button type="primary" @click="doEdit">确 定</el-button>
                    <el-button @click="closeModul">取 消</el-button>
                </div>
            </template>
        </form-dialog>
    </el-container>

</template>

<script>
    import {mapGetters} from 'vuex'
    import formDialog from '../../components/formDialog'
    import Upload from '../../components/upload/index'
    import {
        positionData,
        siteData,
        advertiseData,
        advertiseAdd,
        advertiseEdit,
        advertiseDeleteOne,
        advertiseDeleteMore
    } from '../../api/ads'
    import moment from 'moment'

    const switchTypes = [{label: '小程序子页面', value: 1}, {label: '小程序导航', value: 2}, {label: 'webview活动页', value: 3}]
    export default {
        name: "companyManagement",
        components: {formDialog, Upload},
        data() {
            return {
                tableData: [],
                pageInfo: {
                    totalCount: 0,
                    pageNumber: 1,
                    pageSize: 10,
                    pageOrderName: 'opAt',
                    pageOrderBy: 'descending'
                },
                query: {
                    searchName: '',
                    searchKeyword: ''
                },
                visibleFormDialog: false,
                formData: {
                    positionId: '',
                    title: '',
                    subTitle: '',
                    descriptions: '',
                    switchType: '',
                    url: '',
                    picUrl: '',
                    describeContent: '',
                    orderIndex: '',
                    isShow: '',
                    deadTime: '',
                    createTime: '',
                    sysAd: '',
                    siteId: ''
                },
                editTitle: '',
                formRules: {
                    positionId: [
                        {required: true, message: '位置ID', trigger: 'blur'}
                    ],
                    title: [
                        {required: true, message: '标题', trigger: 'blur'}
                    ],
                    subTitle: [
                        {required: true, message: '副标题', trigger: 'blur'}
                    ],
                    descriptions: [
                        {required: true, message: '描述', trigger: 'blur'}
                    ],
                    switchType: [
                        {required: true, message: '跳转方式', trigger: 'blur'}
                    ],
                    url: [
                        {required: true, message: '跳转地址', trigger: 'blur'}
                    ],
                    picUrl: [
                        {required: true, message: '图片', trigger: 'blur'}
                    ],
                    describeContent: [
                        {required: true, message: 'html内容', trigger: 'blur'}
                    ],
                    orderIndex: [
                        {required: true, message: '排序', trigger: 'blur'}
                    ],
                    isShow: [
                        {required: true, message: '是否展示', trigger: 'blur'}
                    ],
                    deadTime: [
                        {required: true, message: '过期时间', trigger: 'blur'}
                    ],
                    createTime: [
                        {required: true, message: '创建时间', trigger: 'blur'}
                    ],
                    sysAd: [
                        {required: true, message: '是否系统广告', trigger: 'blur'}
                    ],
                    siteId: [
                        {required: true, message: '餐点ID', trigger: 'blur'}
                    ],
                },
                selectData: [],
                posData: [],
                siteDataArr: [],
                switchTypes
            }
        },
        computed: {
            ...mapGetters([
                'userInfo'
            ]),
        },
        methods: {
            formatSwitchType(row, column, cellValue, index) {
                let {switchType} = row
                let status = switchTypes.find(i => i.value === switchType)
                return status ? status.label : '--'
            },
            handleSearch() {
                this.pageInfo.pageNumber = 1;
                this.advertiseData()
            },
            async advertiseData() {
                try {
                    let query = Object.assign({}, this.pageInfo, this.query)
                    let formData = {}
                    for (let i in query) {
                        if (query[i] !== '' && i !== 'total' && query[i] !== -1) {
                            formData[i] = query[i]
                        }
                    }
                    let resonse = await advertiseData(formData)
                    let {data} = resonse;
                    this.tableData = data.list;
                    this.pageInfo.totalCount = data.totalCount;
                } catch (e) {
                    console.log(e)
                }
            },
            clearSearch() {
                this.query = {
                    searchName: '',
                    searchKeyword: ''
                },
                    this.handleSearch()
            },
            handleSizeChange(val) {
                this.pageInfo.pageSize = val
                this.pageInfo.page = 1
                this.advertiseData()
                console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
                this.pageInfo.pageNumber = val
                this.advertiseData()
            },
            handleClose(form) {
                this.$refs[form].resetFields();
                this.formData = {};
                this.visibleFormDialog = false
            },
            handleAdd() {
                this.editTitle = '新增';
                this.visibleFormDialog = true;
            },
            handleEdit(row) {
                console.log(428, row);
                this.editTitle = '修改';
                this.formData = row;
                this.visibleFormDialog = true
            },
            //修改
            async doEdit() {
                let createTime = moment().format('YYYY-DD-MM HH:mm:ss');
                // let requestdata=Object.assign(this.formData,{createTime:createTime});
                this.$refs['editForm'].validate(async (valid) => {
                    if (valid) {
                        try {
                            this.formData.id ? await advertiseEdit(this.formData) : await advertiseAdd(Object.assign(this.formData, {createTime: createTime}));
                            this.$message({
                                type: 'success',
                                message: '更新成功!'
                            });
                            this.visibleFormDialog = false;
                            this.$refs['editForm'].resetFields();
                            this.advertiseData()
                        } catch (e) {

                        }
                    }
                })
            },
            //删除
            async doDelete(row) {
                console.log(308, row);
                this.$confirm('删除后无法恢复，确定删除' + '吗?', '确定', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async () => {
                    try {
                        await advertiseDeleteOne(row.id);
                        this.$message({
                            type: 'success',
                            message: '更新成功!'
                        });
                        this.advertiseData()
                    } catch (e) {
                    }
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
            },
            handleSelectionChange(val) {
                console.log(334, val);
                this.selectData = val;
            },
            closeModul() {
                this.visibleFormDialog = false;
                this.formData = {};
                this.advertiseData();
            },
            async doDelMore() {
                let ids = [];
                this.selectData.forEach(function (val) {
                    ids.push(val.id);
                });
                this.$confirm('删除后无法恢复，确定删除选中选项吗?', '确定', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async () => {
                    try {
                        await advertiseDeleteMore({ids: ids.toString()});
                        this.$message({
                            type: 'success',
                            message: '更新成功!'
                        });
                        this.advertiseData()
                    } catch (e) {
                    }
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
            },
            async positionData() {
                let {data} = await positionData();
                this.posData = data.list;
            },
            async siteData() {
                let {data} = await siteData();
                this.siteDataArr = data.list;
                console.log(482, data.list)
            },
            handleSuccess(res) {
                console.log(495, res)
                this.formData.picUrl = res.data.url;
                this.$forceUpdate();
            }
        },
        mounted() {
            this.advertiseData();
            this.positionData();
            this.siteData();
        }
    }
</script>

<style lang="less">
    .adsdia {
        .el-upload {
            height: 100px;
            width: 100px !important;
            line-height: 100px;
        }
    }

    .btn-group {
        button {
            margin-left: 20px;
        }

    }

    .el-input--prefix .el-input__inner {
        padding-left: 46px;
    }

    .el-input__prefix {
        left: 15px;
        color: #303133;
        i {
            font-style: normal;
        }
    }

    .icon-cursor {
        cursor: pointer;
        margin-right: 10px;
        &:hover {
            color: #348EED;
        }
    }

    .avatar-uploader {
        .el-upload {
            border: 1px solid #DCDFE6;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            width: 72px;
            display: block;
            &:hover {
                border-color: #409EFF;
            }
        }
        .cw-icon-tupian {
            font-size: 24px;
            color: #c0c4cc;
            width: 72px;
            height: 72px;
            line-height: 72px;
            text-align: center;
        }
        .avatar {
            width: 72px;
            height: 72px;
            display: block;
        }
    }
</style>
